import React, {useEffect, useState, useRef} from 'react';
import {useRouter} from "next/router";
import Panel from "@/components/panels/Panel";
import {DateTime} from "luxon";
import PanelButton, {TYPE_CANCEL, TYPE_PLAY, TYPE_REVIEW} from "@/components/panels/PanelButton";
import Image from "next/image";
import Countdown from "@/components/panels/Countdown";
import { usePlayer } from "@/lib/usePlayer";

/**
 * the daily challenge panel. has autoplay functionality
 *
 * @param countdown (boolean) when true, a 3 second countdown appears.
 * @returns {JSX.Element}
 * @constructor
 */
const DailyPanel = ({countdown=false, className=''}) => {
	const router = useRouter();
 	const { data: player, isSuccess } = usePlayer();
	const [dailyCountdown, setDailyCountdown] = useState(countdown);
	const hasPlayedDaily = useRef(false);

	/* c8 ignore next 22 */
	useEffect(() => {
		if(player?.daily?.lastAction) {
			const currentDate = DateTime.now().toFormat('yyyy-MM-dd');
			if (player?.daily?.lastAction === currentDate) {
				hasPlayedDaily.current = true;
			}
		}
	}, [])

	const toggleDaily = () => {
		if (hasPlayedDaily.current) {
			router.push('/daily')
		} else {
			setDailyCountdown(!dailyCountdown);
		}
	};

	const navigateToDaily = () => {
		router.push('/daily')
	};

	const date = DateTime.now().toLocaleString(DateTime.DATE_MED)
	return (
		<Panel
			helpHref={'/help/daily'}
			backgroundImage={'bg-daily-panel'}
			midgroundImage={<Image src={'/panels/daily-mg.svg'} alt={'Daily Challenge icon'} fill={true} />}
			helpTestId={"help-daily"}
			className={className}
			grow={true}
		>
			<div className={'flex flex-col items-center justify-center gap-2'}>
				<div className={'flex flex-col items-center justify-center'}>
					<p className={'text-[15px] font-gBold'}>{date}</p>
					<h2 className={'text-2xl font-gBold'}>Daily Challenge</h2>
				</div>
				{dailyCountdown ?
					<>
						<p className={'text-center px-8 text-2xl pb-2 font-gReg'}>Beginning in <Countdown length={3} onComplete={navigateToDaily} />...</p>
						<PanelButton type={TYPE_CANCEL} onClick={toggleDaily} />
					</> :
					<>
						<p className={'text-center px-8 leading-5 text-base pb-2 font-gReg w-96'}>6 curated questions from our classic categories every day!</p>
						{player?.daily?.state === 'Summary' ? <PanelButton type={TYPE_REVIEW} onClick={toggleDaily} />
						: <PanelButton type={TYPE_PLAY} onClick={toggleDaily} />}
					</>
				}
			</div>
		</Panel>
	);
};

export default DailyPanel;
