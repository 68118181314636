import React, {useEffect, useRef, useState} from 'react';
import router from "next/router";
import toast from "react-hot-toast";
import Panel from "@/components/panels/Panel";
import PanelSpinner from "@/components/panels/PanelSpinner";
import PanelButton, {TYPE_CANCEL, TYPE_CREATE} from "@/components/panels/PanelButton";
import {ENDLESS_PROMPT_MAX, ENDLESS_PROMPT_MIN} from "@/lib/constants.mjs";
import {isEndlessInputValid, isTruthy, sendGtagEvent} from "@/lib/utils";
import ReCAPTCHA from "react-google-recaptcha";
import { getPublicConfig } from '@/pages/api/config';
import Image from "next/image";
import Countdown from "@/components/panels/Countdown";
import { usePlayer } from "@/lib/usePlayer";
import { useChangePlayerGame } from "@/lib/usePlayerGame";
import RunsRemaining from "@/components/common/RunsRemaining";

const TextInput = ({value, placeholder='', minLength=ENDLESS_PROMPT_MIN, maxLength=ENDLESS_PROMPT_MAX, isValid=true, onChange=()=>null, onKeyDown}) => {
	const [shake, setShake] = useState(false)
	const timeout = useRef()

	/* c8 ignore next 8 */
	useEffect(() => {
		if (!isValid) {
			setShake(true);
			timeout.current = setTimeout(() => {
				setShake(false)
			}, 500)
		}

		return () => {
			clearTimeout(timeout.current)
		}
	}, [isValid])

	return <div className={`w-full py-2 px-3 rounded-full border-solid border-2 ${!shake && isValid ? 'border-transparent' : 'border-danger'} focus-within:border-violet-600 bg-white focus-within:bg-opacity-100 ${shake ? 'animate-shake' : ''}`}>
		<input
			type={'text'}
			id="prompt"
			data-testid="prompt"
			placeholder={placeholder}
			className={'w-full bg-transparent placeholder:font-gBold placeholder:opacity-75 focus:outline-none text-primary'}
			value={value?.length ? value : ''}
			minLength={minLength}
			maxLength={maxLength}
			onChange={e => onChange(e.target.value)}
			onKeyDown={(e) => onKeyDown(e)}
		/>
	</div>
}

/**
 * handles userInput for custom playlist. has autoplay functionality
 *
 * @param topic (string) should represent the topic that was just played. when autoplay is true, this is displayed to let the user know they will continue with question in this topic
 * @param autoplay (boolean) when true, a 10 second countdown appears.
 * @param onAutoplay (function) called when countdown timer reaches 0
 * @param onCancelAutoplay (function) called when a user cancels a autoplay countdown
 * @param onCreatePlaylist (function) called when user submits a valid topic. the user input (string) is passed as a single function argument
 * @returns {JSX.Element}
 * @constructor
 */
const PlaylistPanel = ({topic='', autoplay=false, onAutoplay=()=>null, onCancelAutoplay=()=>null}) => {
	const { data: player } = usePlayer();
	const { mutateAsync } = useChangePlayerGame();

	const captchaEnabled = isTruthy(getPublicConfig('captchaEnabled'));
	const captchaKey = captchaEnabled ? getPublicConfig('captchaSiteKey') : null
	const [userInput, setUserInput] = useState(null)
	const [isValid, setIsValid] = useState(true)
	const captchaRef = useRef(null);

	/* c8 ignore next 5 */
	const handleKeyDown = (event) =>{
		if(event.key === 'Enter'){
			onSubmit();
		}
	}

	/* c8 ignore next 12 */
	const onCreatePlaylist = async (topic, uid, captchaToken) => {
		await mutateAsync({
			uid, 
			body: { topic, source: 'playlist_panel' }, 
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				...(captchaToken && { "x-captcha-token": captchaToken })
		}});
		sendGtagEvent({
			action: 'endless_prompt_submit',
			category: 'engagement',
			label: 'Endless Prompt Submit',
			value: 'click',
			user_id: uid,
			topic: topic,
		});
		router.push(`/infinite`)
	}

	/* c8 ignore next 23 */
	const onSubmit = async () => {
		const topic = userInput.trim();
		const valid = isEndlessInputValid(topic)
		setIsValid(valid)
		if (valid) {
			try {
				if (!captchaEnabled) {
					return onCreatePlaylist(topic, player?.uid);
				}
	
				const token = await captchaRef.current.executeAsync();
				if (!token) {
					return toast.error("Solve the reCAPTCHA challenge!");
				}
	
				onCreatePlaylist(topic, player?.uid, token);
			} catch (err) {
				toast.error(
					"Something went wrong while verifying the reCAPTCHA challenge. Please refresh the page and try again!"
				);
			}
		}
	}

	return (
		<Panel
			helpHref={'/help/infinite'}
			backgroundImage={'bg-playlist-panel'}
			midgroundImage={<Image src={'/panels/playlist-mg.svg'} alt={'Trivia Playlist icon'} fill={true} />}
			helpTestId={"help-infinite"}
			grow={true}
		>
			<div className={'min-h-[250px] flex flex-col items-center justify-center gap-2'}>
				{ /* c8 ignore next 9 */}
				{autoplay ? (
					<>
						<div className={'flex flex-col items-center justify-center'}>
							<h2 className={'text-sm font-gBold'}>Trivia Playlist</h2>
							<h3 className={'text-2xl font-gBold'}>More {topic}</h3>
						</div>
						<p className={'text-center px-8 text-2xl pb-2'}>Beginning in <Countdown onComplete={onAutoplay} />...</p>
						<PanelButton type={TYPE_CANCEL} textColor={'text-playlist'} onClick={onCancelAutoplay} />
					</> ) : ( <>
					{player?.runsRemaining >= 1 ? (
							<>
								<div className={'flex flex-col items-center justify-center'}>
									<p className={'text-[15px] font-gBold'}>Make your own</p>
									<h2 className={'text-2xl font-gBold'}>Trivia Playlist</h2>
								</div>
								<p className={'text-center leading-5 font-gReg w-72'}>Enter any subject, topic, or theme you&apos;re passionate about. Our AI will generate 6 questions for you to play and share!</p>
								<RunsRemaining />
								<div className={'w-full px-4'}>
									<TextInput
										placeholder={'ex: 90s Pop Music'}
										minLength={ENDLESS_PROMPT_MIN}
										maxLength={ENDLESS_PROMPT_MAX}
										value={userInput}
										onChange={setUserInput}
										isValid={!userInput?.length || isValid}
										onKeyDown={handleKeyDown}
									/>
								</div>
								<PanelButton type={TYPE_CREATE} disabled={!userInput?.length} onClick={onSubmit} />
								{captchaEnabled && <ReCAPTCHA ref={captchaRef} sitekey={captchaKey} size="invisible" />}
							</>
						) : player?.runsRemaining <= 0 ? (
							<>
								<div className={'flex flex-col items-center justify-center'}>
									<h3 className={'text-sm font-gBold'}>Make your own</h3>
									<h2 className={'text-2xl font-gBold'}>Trivia Playlist</h2>
								</div>
								<p className={'text-center px-8 text-sm pb-2'}>
									{`You've reached your daily limit of trivia playlists. Check back tomorrow for more!`}
								</p>
							</>
						) : (
							<PanelSpinner />
						)
					} </> )
				}
			</div>
		</Panel>
)};

export default PlaylistPanel;
