import CheckboxFalse from "../icons/Checkbox_false.svg";
import CheckboxTrue from "../icons/Checkbox_true.svg";

const Checkbox = ({
  disabled = false,
  checked = true,
  id,
  label,
  onChange = () => {},
}) => (
  <div className="w-full flex gap-2" onClick={onChange}>
    <input
      className="hidden peer relative appearance-none shrink-0 w-4 h-4 mt-1"
      type="checkbox"
      disabled={disabled}
      id={id}
      data-testid={id}
      defaultChecked={checked}
    />
    <div className="w-full]"> 
      {checked ? <CheckboxTrue/> : <CheckboxFalse/>}
    </div>
    <label htmlFor={id} onClick={onChange} className="font-gReg leading-5 text-primary text-base pl-1">
      {label}
    </label>
  </div>
);

export default Checkbox;