import { usePlayer } from "@/lib/usePlayer";

const RunsRemaining = ({className=''}) => {
  const { data: player } = usePlayer();

  switch(player?.runsRemaining) {
    case 0:
      return <div className={`${className} text-center text-md font-gExtBlack`}>
        {`Come back tomorrow for more trivia!`}
      </div>
    case 1:
      return <div className={`${className} text-center text-md font-gExtBlack`}>
        {`You have one pursuit remaining today.`}
      </div>
    case 2:
      return <div className={`${className} text-center text-md font-gExtBlack`}>
        {`You have two pursuits remaining today.`}
      </div>
    case 3:
      return <div className={`${className} text-center text-md font-gExtBlack`}>
        {`You have three pursuits remaining today.`}
      </div>
    default:
      return <div className={`${className} text-center text-md font-gExtBlack`}>
        {`You have ${player?.runsRemaining} pursuits remaining today.`}
      </div>
  }
};

export default RunsRemaining;
