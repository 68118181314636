import React, { useEffect, useState } from "react";
import CommonButton from "@/components/common/buttons/CommonButton";
import Checkbox from "@/components/common/Checkbox";
import {STORAGE_KEY, sendGtagEvent} from "@/lib/utils";
import toast from "react-hot-toast";
import { useBraze } from "../braze";
import sha256 from "crypto-js/sha256";
import { usePlayer } from "@/lib/usePlayer";

/* c8 ignore next 9 */
const onSubscribe = async (uid) => {
	sendGtagEvent({
		action: 'braze_email_subscribe',
		category: 'engagement',
		label: 'Braze Email Subscribe',
		value: 'click',
		user_id: uid
	});
}

const EmailPanel = ({className}) => {
	const { data: player } = usePlayer();

	const brazeClient = useBraze();
	const [optingIn, setOptingIn] = React.useState(true);
	const [email, setEmail] = React.useState("");
	const [invalidEmail, setInvalidEmail] = React.useState(false);
	const [shake, setShake] = useState(false);
	const [subscribed, setSubscribed] = useState(false);

	/* c8 ignore next 30 */
	const handleSubmit = async (event) => {
		event.preventDefault();

		const brazeUser = brazeClient.getUser();

		if (optingIn) {
      const isEmailValid = brazeUser?.setEmail(email);
      if (!isEmailValid) {
        setInvalidEmail(true);
        return (
          !shake &&
          setShake(
            setTimeout(() => {
              setShake(false);
            }, 500)
          )
        );
      }
      setInvalidEmail(false);
      brazeUser?.setEmailNotificationSubscriptionType("opted_in");

      // add hashed email as external_id
      brazeClient.changeUser(sha256(email).toString());
    }

		brazeUser?.addAlias(player?.uid, "uid");

		await enableOptIn(player?.uid);
		return onSubscribe(player?.uid);
	}

	/** Enables endless mode for a user */
	/* c8 ignore next 20 */
	const enableOptIn = async (uid) => {
		const res = await fetch(`/api/user/${uid}/braze/subscribe`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		if (res.ok) {
			toast.success(
				optingIn
					? "Subscribed to receive e-mail communications"
					: "Email submitted successfully. Have fun playing!"
			);
			setSubscribed(true);
			return true;
		}
		toast.error("Something went wrong while subscribing for e-mail communications");
		return false;
	};

	return (
		!player?.optedIn && 
		<form
			data-testid="braze-email-prompt"
			onSubmit={handleSubmit}
			className={`
			z-auto relative p-2 rounded-md min-w-[320px] w-full max-w-[480px] xl:min-w-none xl:max-w-none min-h-[50px] flex items-center justify-center mx-auto
			bg-neutral-300 bg-opacity-60
			bg-contain
			overflow-hidden ${className}
		`}>
			<div
				className={'relative w-full h-full flex flex-col items-center justify-between'}>
				{ !subscribed ? ( 
					<div className={'flex flex-col flex-grow p-2 items-center'}>
						<h2 className={'font-gBold text-xl text-center text-primary max-w-[350px]'}>
							Join the Trivial Pursuit community to learn about upcoming games, app updates, and more!
						</h2>
						<p className={'text-primary text-xl p-3 font-gReg'}>Enter your email address:</p>
						<div
							className={`w-full py-2 px-3 rounded-full border-solid border-2 ${!shake && !invalidEmail ? 'border-transparent' : 'border-danger'} focus-within:border-violet-600 bg-white focus-within:bg-opacity-100 ${shake ? 'animate-shake' : ''}`}
						>
							<input
								name="email"
								placeholder="Enter your email address"
								className="w-full bg-transparent placeholder:font-gBold placeholder:opacity-75 focus:outline-none text-primary"
								type="email"
								id="email"
								data-testid="email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className={"text-base flex gap-2 pb-2 my-4 w-11/12"}>
							<Checkbox 
								label={`"I'm opting in! I want to be among the first to find out about Trivial Pursuit Infinite updates by receiving e-mail communications."`}
								id='link-checkbox' 
								checked={optingIn}
								onChange={() => setOptingIn((prev) => !prev)}
							/>
						</div>
						<CommonButton
							label={"Submit"}
							enabled={email && optingIn}
							onClick={() => null}
							type="submit"
						/>
					</div>
				):(
					<div className={'text-primary font-gSemi my-auto p-4'}>
						Thank you for signing up! ✉️
					</div>
				)}
			</div>
		</form>		
	);
};

export default EmailPanel;
