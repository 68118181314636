import 'dotenv/config'
import React, { useEffect, useState } from 'react';
import Page from "@/components/layout/Page";
import DailyPanel from "@/components/panels/DailyPanel";
import EmailPanel from "@/components/panels/EmailPanel";
import InfoPanel from "@/components/panels/InfoPanel";
import PlaylistPanel from '@/components/panels/PlaylistPanel';
import Disclaimer from "@/components/panels/Disclaimer";
import ShopPanel from "@/components/panels/ShopPanel";
import TrendingPanel from '@/components/panels/TrendingPanel';
import StaffPanel from '@/components/panels/StaffPanel';
import LiveModePanel from '@/components/panels/LiveModePanel';
import LeaderboardModal from '@/components/live/LeaderboardModal';
import PanelGrid from "@/components/panels/PanelGrid";
import Loading from "@/components/common/Loading";
import { usePlayer } from "@/lib/usePlayer";

const HomePage = () => {
  const { isSuccess } = usePlayer();

  if (isSuccess) {
		return (
			<Page>
				<PanelGrid className={''}>
					<div className="grid gap-2 mb-2 order-1 xl:col-span-3">
						<DailyPanel className={"grid gap-4 order-1"} />
						<LiveModePanel className={"order-2"} />
						<div className={"hidden order-3 xl:inline-flex"} >
							<PlaylistPanel />
						</div>
					</div>
					<div className="grid gap-2 mb-2 order-2 xl:col-span-4">
						<div className={"grid gap-4 order-0"} >
							<StaffPanel />
						</div>
						<div className={"grid gap-4 order-1 xl:order-3"}>
							<TrendingPanel />
						</div>
						<div className={"xl:hidden inline-flex order-3"} >
							<PlaylistPanel />
						</div>
					</div>
					<div className="grid gap-2 mb-2 order-3 xl:col-span-3">
						<ShopPanel/>
						<EmailPanel />
						<InfoPanel className="mx-auto"/>
					</div>
				</PanelGrid>
				<Disclaimer/>
				{/* <LeaderboardModal /> */}
			</Page>
		);
	}

	return (
		<Page pageTitle="Trivial Pursuit Infinite">
			<Loading />
		</Page>  
	)
};

export default HomePage;
